/*
	In SSR
		query/existingParams = context.query
	In CSR
		query/existingParams = router.query
*/

export const createURLSearchParams = query => {
	if ( !Object.keys ( query ).length )
		return "";

	const params = new URLSearchParams ();

	// Populate params with all context query parameters
	Object.keys ( query ).forEach ( key => {
		params.append ( key, query[key] );
	} );

	return `?${ params }`;
};

export const updateURLParams = updates => {
	const existingParams = window.location.search;
	const updatedParams  = new URLSearchParams ( existingParams );

	// Iterate through the updates object and update the parameters
	for ( const key in updates ) {
		if ( Object.prototype.hasOwnProperty.call ( updates, key ) ) {
			updatedParams.set ( key, updates[key] );
		  }
	}

	// Convert the updatedParams back to a string using .toString()
	return `?${ updatedParams.toString () }`;
};

// client side
export const getParamsFromURL =  () => {
	const urlSearchParams = new URLSearchParams ( window.location.search );

	return Object.fromEntries ( urlSearchParams.entries () );
};

export const getParamValueFromQueryString = searchString => {
	if ( typeof window === "undefined" )
		return;
	// Get the query string portion of the URL
	const queryString = window?.location?.search;

	// Use the URLSearchParams API to parse the query string
	const searchParams = new URLSearchParams ( queryString );

	// Get the value of the string parameter you want to search
	const searchedString = searchParams.get ( searchString );

	return searchedString;

};
