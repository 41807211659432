export const mobile = "(max-width: 460px)";
export const tablet = "(max-width: 992px) and (min-width: 461px)";
export const desktop = "(min-width: 992px)";
export const mobileAndTablet = "(max-width: 992px)";

export const rooterMediaQuery = {
	mobile          : `@media only screen and ${ mobile }`,
	tablet          : `@media only screen and ${ tablet }`,
	desktop         : `@media only screen and ${ desktop }`,
	mobileAndTablet : `@media only screen and ${ mobileAndTablet }`
};