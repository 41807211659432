/**
 * Description placeholder
 *
 * @param {Object} props
 * @param {boolean} props.condition
 * @param {React.ReactNode} props.children
 * @returns {React.ReactNode | null}
 */
function Render ( { condition, children } ) {
	if ( !condition )
		return null;

	return children;
}

export default Render;
