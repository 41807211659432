import { atom, useAtomValue, useSetAtom } from "jotai";
import { atomWithStorage }                from "jotai/utils";

import { storeInCookie } from "_common/utils/user";

export const loginPopupAtom = atom ( false );
export const localePopupAtom = atom ( false );
export const drawerAtom = atom ( false );
export const interestialPopupAtom = atom ( false );
export const liveChatInfoPopupAtom = atom ( false );
export const isWebviewAtom = atom ( false );
export const theatreModeAtom = atom ( false );
export const isSidebarMenuOpenAtom = atom ( false );
export const isFollowStatusAtom = atom ( false );
export const openUserSettingModalAtom = atom ( false );
export const isBlockedByStreamerAtom = atom ( false );
export const languagesListAtom = atom ( [] );
export const openUserOnboardingModalAtom = atom ( false );
export const isAuthCookieStoredAtom = atom ( false );
export const isOpenChatContainer = atom ( false );
export const chatContainerTabName = atom ( "liveChat" ); // liveChat || fanrank || donation
export const deviceTypeAtom = atom ();
export const userCountryAtom = atom ();
export const adConfigAtom = atom ( {} );

export const darkModeAtom = atomWithStorage ( "darkMode", true );
export const appLocaleAtom = atomWithStorage ( "app-locale", "en" );
export const systemThemeAtom = atomWithStorage ( "isDeviceTheme", undefined );
export const userOnBoardingAbortedAtom = atomWithStorage ( "userOnBoardingAborted", false );

export const popularStreamingGamesList = atom ( [] );
export const popularCreatorsList = atom ( [] );

export const useGetIsWebview = () => useAtomValue ( isWebviewAtom );

export const useSetLoginPopup = () => {
	return useSetAtom ( loginPopupAtom );
};

export const useGetLoginPopup = () => {
	return useAtomValue ( loginPopupAtom );
};

export const useSetLocalePopup = () => {
	return useSetAtom ( localePopupAtom );
};

export const useGetLocalePopup = () => {
	return useAtomValue ( localePopupAtom );
};

export const useSetDrawer = () => {
	return useSetAtom ( drawerAtom );
};

export const useGetDrawer = () => {
	return useAtomValue ( drawerAtom );
};

export const useSetInterestialPopup = () => {
	return useSetAtom ( interestialPopupAtom );
};

export const useGetInterestialPopup = () => {
	return useAtomValue ( interestialPopupAtom );
};

export const useSetLiveChatInfoPopup = () => {
	return useSetAtom ( liveChatInfoPopupAtom );
};

export const useGetLiveChatInfoPopup = () => {
	return useAtomValue ( liveChatInfoPopupAtom );
};

export const useSetTheatreMode = () => {
	return useSetAtom ( theatreModeAtom );
};

export const useGetTheatreMode = () => {
	return useAtomValue ( theatreModeAtom );
};

export const useSetIsSidebarMenuOpen = () => {
	return useSetAtom ( isSidebarMenuOpenAtom );
};

export const useGetIsSidebarMenuOpen = () => {
	return useAtomValue ( isSidebarMenuOpenAtom );
};

export const useSetIsFollowStatus = () => {
	return useSetAtom ( isFollowStatusAtom );
};

export const useGetIsFollowStatus = () => {
	return useAtomValue ( isFollowStatusAtom );
};

export const useSetOpenUserSettingModal = () => {
	return useSetAtom ( openUserSettingModalAtom );
};

export const useGetOpenUserSettingModal = () => {
	return useAtomValue ( openUserSettingModalAtom );
};

export const useSetIsBlockedByStreamer = () => {
	return useSetAtom ( isBlockedByStreamerAtom );
};

export const useGetIsBlockedByStreamer = () => {
	return useAtomValue ( isBlockedByStreamerAtom );
};

export const useSetLanguagesList = () => {
	return useSetAtom ( languagesListAtom );
};

export const useGetLanguagesList = () => {
	return useAtomValue ( languagesListAtom );
};

export const useSetOpenUserOnboardingModal = () => {
	return useSetAtom ( openUserOnboardingModalAtom );
};

export const useGetOpenUserOnboardingModal = () => {
	return useAtomValue ( openUserOnboardingModalAtom );
};

export const useSetAppLocale = () => {
	return useSetAtom ( appLocaleAtom );
};

export const useGetAppLocale = () => {
	return useAtomValue ( appLocaleAtom );
};

export const useSetDeviceType = () => {
	return useSetAtom ( deviceTypeAtom );
};

export const useGetDeviceType = () => {
	return useAtomValue ( deviceTypeAtom );
};

export const useSetUserCountryCode = () => {
	return useSetAtom ( userCountryAtom );
};

export const useGetUserCountryCode = () => {
	return useAtomValue ( userCountryAtom );
};

export const useSetAdConfig = () => {
	return useSetAtom ( adConfigAtom );
};

export const useGetAdConfig = () => {
	return useAtomValue ( adConfigAtom );
};

export const useSetDarkMode = () => {
	const setDarkMode = useSetAtom ( darkModeAtom );

	return value => {
		if ( value === undefined )
			return;

		if ( document && document.getElementsByTagName ( "html" )?.[0] ) {
			document.getElementsByTagName ( "html" )[0].style.colorScheme = value ? "dark" : "light";
			document.getElementsByTagName ( "html" )[0].style.background  = value ? "black" : "white";
		}

		setDarkMode ( value );
		storeInCookie ( "darkMode", value, { expires: 30 } );
	};
};

export const useGetDarkMode = () => {
	return useAtomValue ( darkModeAtom );
};

export const useSetDeviceTheme = () => {
	return useSetAtom ( systemThemeAtom );
};

export const useGetDeviceTheme = () => {
	return useAtomValue ( systemThemeAtom );
};

export const useSetUserOnBoardingAborted = () => {
	return useSetAtom ( userOnBoardingAbortedAtom );
};

export const useGetUserOnBoardingAborted = () => {
	return useAtomValue ( userOnBoardingAbortedAtom );
};

export const useSetPopularStreamingGamesList = () => {
	return useSetAtom ( popularStreamingGamesList );
};

export const useGetPopularStreamingGamesList = () => {
	return useAtomValue ( popularStreamingGamesList );
};

export const useSetPopularCreatorsList = () => {
	return useSetAtom ( popularCreatorsList );
};

export const useGetPopularCreatorsList = () => {
	return useAtomValue ( popularCreatorsList );
};

export const useSetIsAuthCookieStored = () => {
	return useSetAtom ( isAuthCookieStoredAtom );
};

export const useGetIsAuthCookieStored = () => {
	return useAtomValue ( isAuthCookieStoredAtom );
};

export const useGetIsOpenChatContainer = () => {
	return useAtomValue ( isOpenChatContainer );
};

export const useToggleChatContainer = () => {
	const setIsOpenChatContainer  = useSetAtom ( isOpenChatContainer );
	const setChatContainerTabName = useSetAtom ( chatContainerTabName );

	return value => {
		setIsOpenChatContainer ( value );
		if ( !value ) {
			setChatContainerTabName ( "liveChat" ); // to reset the current tab while closing chat container
		}
	};
};

export const useGetChatContainerTabName = () => {
	return useAtomValue ( chatContainerTabName );
};

export const useSetChatContainerTabName = () => {
	const setChatContainerTabName = useSetAtom ( chatContainerTabName );

	return value => {
		setChatContainerTabName ( value );
	};
};
