import { useMediaQuery as mantineUseMediaQuery } from "@mantine/hooks";
import { useEffect }                             from "react";

import useDeviceDetection          from "_common/hooks/useDeviceDetection";
import { desktop, mobile, tablet } from "_styles/theme/mediaQuery";

import { useSetDeviceType } from "./global";

const useMediaQuery = () => {
	const setDeviceType = useSetDeviceType ();
	const isMobile      = mantineUseMediaQuery ( mobile );
	const isTablet      = mantineUseMediaQuery ( tablet );
	const isDesktop     = mantineUseMediaQuery ( desktop );
	const device        = useDeviceDetection ();

	useEffect ( () => {
		let currentDevice;

		if ( isDesktop ) currentDevice = "desktop";
		if ( isTablet ) currentDevice = "tablet";
		if ( isMobile ) currentDevice = "mobile";

		if ( device === "Mobile" )
			currentDevice = "mobile";

		if ( !currentDevice )
			return;

		setDeviceType ( currentDevice );
	}, [ isTablet, isMobile, isDesktop, device ] );

	return null;
};

export default useMediaQuery;
