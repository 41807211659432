export const BROADCAST_SOCKET_DEV_CONFIG = {
	apiKey            : "AIzaSyCLOhpNuGaGsvLbDCt0IOCrHD8jd-9U_AE",
	authDomain        : "rooter-broadcast-development.firebaseapp.com",
	databaseURL       : "https://rooter-broadcast-development.firebaseio.com",
	projectId         : "rooter-broadcast-development",
	storageBucket     : "rooter-broadcast-development.appspot.com",
	messagingSenderId : "363616033348",
	appId             : "1:363616033348:web:dfdd5d729e807f2a"
};
export const BROADCAST_SOCKET_PROD_CONFIG = {
	apiKey            : "AIzaSyCibTaBjr6QrLdlaW8aILa-Q-xaq0apnw8",
	authDomain        : "rooter-broadcast-prod.firebaseapp.com",
	databaseURL       : "https://rooter-broadcast-prod.firebaseio.com",
	projectId         : "rooter-broadcast-prod",
	storageBucket     : "rooter-broadcast-prod.appspot.com",
	messagingSenderId : "1048460945051",
	appId             : "1:1048460945051:web:7debb41a05633705"
};
export const WEB_CONFIG = {
	apiKey            : "AIzaSyDH7_9UFZ346h-gdt9qaaKPkW6hw6xQqnc",
	authDomain        : "web-firebase-add4b.firebaseapp.com",
	databaseURL       : "https://web-firebase-add4b.firebaseio.com",
	projectId         : "web-firebase-add4b",
	storageBucket     : "web-firebase-add4b.appspot.com",
	messagingSenderId : "724498387292",
	appId             : "1:724498387292:web:6a82749cd9b2da2cb03199",
	measurementId     : "G-XHLEY8995X"
};
export const SPORTSX_CONFIG = {
	apiKey            : "AIzaSyAWt7uE3bNkSWAnuI0wy2OqifFSCKS1iPY",
	authDomain        : "sportsx-1243.firebaseapp.com",
	databaseURL       : "https://sportsx-1243.firebaseio.com",
	projectId         : "sportsx-1243",
	storageBucket     : "sportsx-1243.appspot.com",
	messagingSenderId : "1027838049870",
	appId             : "1:1027838049870:web:5579737c0f5b1f6ef40d1f",
	measurementId     : "G-CZTPY8QLJV"
};
