import languages from "./lang.json";

const resources = () => {
	const __obj = {};

	for ( let lang in languages ) {
		__obj[lang] = {
			"translation": languages[lang]
		};
	}

	return __obj;
};

export default resources;
